body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.mg-0 {
    margin: 0px;
}

.mg-5 {
    margin: 5px;
}

.mg-10 {
    margin: 10px;
}

.mg-15 {
    margin: 15px;
}

.mg-20 {
    margin: 20px;
}

.mgr-0 {
    margin-right: 0px !important;
}

.mgr-5 {
    margin-right: 5px !important;
}

.mgr-10 {
    margin-right: 10px !important;
}

.mgr-15 {
    margin-right: 15px !important;
}

.mgr-20 {
    margin-right: 20px !important;
}

.mgr-30 {
    margin-right: 30px !important;
}

.mgl-0 {
    margin-left: 0px !important;
}

.mgl-5 {
    margin-left: 5px !important;
}

.mgl-7 {
    margin-left: 7px !important;
}

.mgl-10 {
    margin-left: 10px !important;
}

.mgl-15 {
    margin-left: 15px !important;
}

.mgl-20 {
    margin-left: 20px !important;
}

.mgl-30 {
    margin-left: 30px !important;
}

.mgt-0 {
    margin-top: 0px !important;
}

.mgt-5 {
    margin-top: 5px !important;
}

.mgt-10 {
    margin-top: 10px !important;
}

.mgt-15 {
    margin-top: 15px !important;
}

.mgt-20 {
    margin-top: 20px !important;
}

.mgt-30 {
    margin-top: 30px !important;
}

.mgt-50 {
    margin-top: 50px !important;
}

.mgb-0 {
    margin-bottom: 0px !important;
}

.mgb-5 {
    margin-bottom: 5px !important;
}

.mgb-10 {
    margin-bottom: 10px !important;
}

.mgb-15 {
    margin-bottom: 15px !important;
}

.mgb-20 {
    margin-bottom: 20px !important;
}

.mgb-30 {
    margin-bottom: 30px !important;
}

.padr-5 {
    padding-right: 5px !important;
}

.padr-10 {
    padding-right: 10px !important;
}

.padr-15 {
    padding-right: 15px !important;
}

.padr-20 {
    padding-right: 20px !important;
}

.padr-50 {
    padding-right: 50px !important;
}

.padl-5 {
    padding-left: 5px !important;
}

.padl-10 {
    padding-left: 10px !important;
}

.padl-15 {
    padding-left: 15px !important;
}

.padl-20 {
    padding-left: 20px !important;
}

.padl-30 {
    padding-left: 30px !important;
}

.padl-50 {
    padding-left: 50px !important;
}

.padt-5 {
    padding-top: 5px !important;
}

.padt-10 {
    padding-top: 10px !important;
}

.padt-15 {
    padding-top: 15px !important;
}

.padt-20 {
    padding-top: 20px !important;
}

.padt-50 {
    padding-top: 50px !important;
}

.padb-0 {
    padding-bottom: 0px !important;
}

.padb-5 {
    padding-bottom: 5px !important;
}

.padb-10 {
    padding-bottom: 10px !important;
}

.padb-15 {
    padding-bottom: 15px !important;
}

.padb-20 {
    padding-bottom: 20px !important;
}

.padb-50 {
    padding-bottom: 50px !important;
}

.pad-0 {
    padding: 0px;
}

.pad-5 {
    padding: 5px;
}

.pad-10 {
    padding: 10px;
}

.pad-15 {
    padding: 15px;
}

.pad-20 {
    padding: 20px;
}

.w-700,
.bold {
    font-weight: 700;
}

.w-600 {
    font-weight: 600;
}

.w-500 {
    font-weight: 500;
}

.w-400 {
    font-weight: 400;
}

.w-300 {
    font-weight: 300;
}

.w-200 {
    font-weight: 200;
}

.w-100 {
    font-weight: 100;
}

.font-70 {
    font-size: 70%;
}

.font-75 {
    font-size: 75%;
}

.font-80 {
    font-size: 80%;
}

.font-90 {
    font-size: 90%;
}

.font-110 {
    font-size: 110%;
}

.font-120 {
    font-size: 120%;
}

.font-130 {
    font-size: 130%;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.upper {
    text-transform: uppercase;
}

.lower {
    text-transform: lowercase;
}

.italic {
    font-style: italic;
}

.tfont {
    font-family: 'Montserrat', 'Roboto', 'Ubuntu', sans-serif;
}

.label {
    font-family: 'Montserrat', 'Roboto', 'Ubuntu', sans-serif;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 3px;
    color: #555;
}

.small-label {
    font-family: 'Montserrat', 'Roboto', 'Ubuntu', sans-serif;
    text-transform: uppercase;
    font-size: 9.15px;
    font-weight: 400;
    margin-bottom: 3px;
    color: #555;
}

.primary {
    color: #d9a366;
}

.secondary {
    color: #f1c086;
}

.grey {
    color: #777;
}

.light-grey-1 {
    color: #999;
}

.light-grey-2 {
    color: #aaa;
}

.light-grey-3 {
    color: #ccc;
}

.rel {
    position: relative;
}

.abs {
    position: absolute;
}

.bordered-1 {
    border: 1px solid #ededed;
}

.bordered-1-left {
    border-left: 1px solid #ededed;
}

.bordered-1-right {
    border-right: 1px solid #ededed;
}

.clickable {
    cursor: pointer;
}

.hover-highlight {
    transition: all ease-in 150ms;
}

.hover-highlight:hover {
    background-color: #f1c086 !important;
    color: white !important;
}

.animated {
    transition: all ease-in 150ms;
}